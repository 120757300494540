// 万
export function withSuffix(suffix?: number, suffix2?: string) {
  if (!suffix) {
    return ''
  }
  if (suffix < 9999) {
    return suffix.toString()
  }
  if (suffix > 9999 && suffix < 99999999) {
    return `${(suffix / 10000).toFixed(1)}${suffix2 ?? '万'}`
  }
}
// 时间
export function formatSecondsToTime(seconds?: number) {
  if (!seconds) {
    return ''
  }

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60

  // 使用 padStart 方法来确保小时、分钟和秒都是两位数
  const hoursStr = String(hours).padStart(2, '0')
  const minutesStr = String(minutes).padStart(2, '0')
  const secsStr = String(secs).padStart(2, '0')

  // 返回格式化后的时间字符串
  return `${hoursStr}:${minutesStr}:${secsStr}`
}

// vip 天数
export function formatValidDay(day?: number) {
  if(!day)
    return '0天'
  if (day >= 36500) {
    return '永久'
  }
  return `${day}天`
}
